// Plugins
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification';
import Multiselect from 'vue-multiselect';
import VModal from 'vue-js-modal';
import VueLazyload from 'vue-lazyload';
import * as VeeValidate from 'vee-validate';

// Components
import SecondaryToolbar from 'shared/ui/components/form-components/SecondaryToolbar.vue';
import VueMarkdown from '@adapttive/vue-markdown/src/VueMarkdown.js';
import Loader from 'shared/ui/components/Loader.vue';

// Directives
import CodeHighlight from 'ui/utils/directives/code-highlight';

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        app.use(VTooltip, { defaultHtml: false });
        app.use(VueClipboard);
        app.use(Notifications);
        app.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true });
        app.use(VueLazyload);

        const GLOBAL_COMPONENTS = {
            'validation-provider': VeeValidate.ValidationProvider,
            'validation-observer': VeeValidate.ValidationObserver,
            'Loader': Loader,
            'VueMarkdown': VueMarkdown,
            'Multiselect': Multiselect,
            'SecondaryToolbar': SecondaryToolbar,
        };

        // Global components registration
        for (const componentName of Object.keys(GLOBAL_COMPONENTS)) {
            app.component(componentName, GLOBAL_COMPONENTS[componentName]);
        }

        app.directive('code-highlight', CodeHighlight);
    }
};
