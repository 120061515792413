// Utils
import { get, merge } from 'lodash';

// Runtimes
import VueI18n from 'vue-i18n';
import * as VeeValidate from 'vee-validate';

// Messages
import * as messages from 'locales/index';
import en from './locales/en.json';

/** @param {import('vue').VueConstructor} app */
export function createLocales(app) {
    const LOCALE = app.prototype.$utils.locales.getFirstEligibleLocale(Object.keys(messages));
    const DOCS_LOCALES = { en };

    app.use(VueI18n);

    // Plugins configuration
    const i18n = new VueI18n({
        locale: LOCALE,
        fallbackLocale: 'en',
        messages: merge({ ...messages }, DOCS_LOCALES)
    });

    const defaultOverwrites = get(messages, 'en.validations.overwrites');
    const langOverwrites = get(messages, `${LOCALE}.validations.overwrites`, {});
    const overwrites = merge(langOverwrites, defaultOverwrites);

    VeeValidate.localize(LOCALE, overwrites);

    return i18n;
}
