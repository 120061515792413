/** @module Locales */

// Utils
import { clone, get, isEmpty, isObject, isString, merge, set, cloneDeep } from 'lodash';
import { compile } from 'libs/utils/dom';

import * as DATA from 'langmap';

// Constants
const DEFAULT_LOCALE = 'en';

/**
 * Gets the first overlap language between browser and the given locales.
 * If none is found falls back to 'en'
 *
 * @param {Array} [locales = []] an array of available locales
 * @param {Array} [languages = navigator.languages] an array of client supported languages
 *
 * @returns {String} the first common locale or 'en'
 */
export function getFirstEligibleLocale(locales = [], languages = Array.from(navigator.languages)) {
    let extendedLanguages = [];
    if (typeof Intl === 'object' && typeof Intl.Locale === 'function') {
        languages.forEach(l => {
            const locale = new Intl.Locale(l);
            extendedLanguages.push(locale.baseName);
            if (!extendedLanguages.includes(locale.language)) {
                extendedLanguages.push(locale.language);
            }
        });
    } else {
        extendedLanguages = languages;
    }

    const commonLocales = extendedLanguages.filter(value => locales.includes(value));
    const locale = commonLocales.length ? commonLocales[0] : DEFAULT_LOCALE;
    console.info('[Locales] Common locale detected:', locale);
    return locale;
}

/**
 * Given an i18n object and a locale, this method tries to return the corresponding value.
 *
 * @param {Object} object the object to translate
 * @param {String} [locale=DEFAULT_LOCALE] the locale to use for translation
 *
 * @returns {String} a string representing the translated object.
 */
export function localizeLabel(object, locale = DEFAULT_LOCALE) {
    if (isEmpty(object)) {
        return '';
    }

    if (isString(object)) {
        return object;
    }

    let localizedValue = object[locale];

    if (!isString(localizedValue)) {
        console.warn('[utils/locales] Unable to determine `%s` value for', locale, object);
        localizedValue = object.toString();
    }

    return localizedValue;
}

/**
 * Given a translation path and a i18n dictionary, this method checks if the given key
 * can be used or is trying to overwrite an existing one.
 *
 * @param {String} path the translation path
 * @param {Object} messages the entire i18n dictionary
 *
 * @returns {Boolean} true if the path isn't overwriting anything, false otherwise
 */
export function validateNewI18nKey(path, messages) {
    if (isEmpty(path) || !isString(path)) {
        console.warn('[utils/locales] Translation path is empty or not a string');
        return false;
    }

    if (!isObject(messages)) {
        console.warn('[utils/locales] Given dictionary has a wrong format');
        return false;
    }

    // Early fail if path equal to webapp reserved namespaces:
    // - keys that are present in the webapp i18n file but not in backstage/backend documents
    if (['activation', 'events', 'general', 'store', 'login'].some(prefix => path === prefix)) {
        console.warn('[utils/locales] Forbidden namespace');
        return false;
    }

    // Early fail if illegal prefix is detected
    // * fp_        : SpotMe's CouchDB reserved keywords
    // * _          : CouchDB reserved keywords
    // * .          : translation key path separator
    // * definition : internal backstage's i18n document language definition

    if (['fp_', '_', '.', 'definition'].some(prefix => path.startsWith(prefix))) {
        console.warn('[utils/locales] Forbidden prefix');
        return false;
    }

    // Early fail if illegal suffix is detected
    if (['_', '.'].some(prefix => path.endsWith(prefix))) {
        console.warn('[utils/locales] Forbidden suffix');
        return false;
    }

    const exists = get(messages, `${path}.${DEFAULT_LOCALE}`);

    if (exists) {
        return true;
    }

    /**
     * To fully validate a new key we navigate the given path by splitting it
     * into its parts and check every time that we didn't hit a leaf; if so,
     * we bail in order to prevent things like:
     *
     * {
     *      something: {
     *          en: 'translation',
     *          new_key: {
     *              en: 'invalid nesting'
     *          }
     *      }
     * }
     */
    const parts = path.split('.');
    let key;

    // Early fail if no prefix/suffix
    if (parts.length === 1) {
        console.warn('[utils/locales] prefix and suffix required');
        return false;
    }

    // Early fail if spaces in the first key
    if (/\s/.exec(parts[0])) {
        console.warn('[utils/locales] Spaces not allowed in first key');
        return false;
    }


    for (const part of parts) {
        if (isEmpty(part)) {
            console.warn('[utils/locales] parts cannot be empty');
            return false;
        }
        // rebuild the key piece by piece
        key = key ? `${key}.${part}` : part;
        const translation = get(messages, key);

        if (isObject(translation)) {
            const firstProp = Object.keys(translation)[0];

            if (isString(translation[firstProp])) {
                // At first string element we bail because it
                // means that we hit a leaf.
                console.warn('[utils/locales] Leaf overload forbidden');
                return false;
            }
        }
    }

    /**
     * To be valid at this point we only have to check if the given path
     * is empty, which means:
     *
     * - undefined or null
     * - empty string
     * - empty object
     */
    return isEmpty(get(messages, path));
}

/**
 * Transforms a JSON object into a flat "dot-noted" string key object.
 * I.E.:
 *
 * "account": {
 *      "title": {
 *          "en": "Log in"
 *      }
 *  }
 *
 * will become:
 *
 * `{"account.title.en": "Log in"}`
 *
 * @param {object} messages the i18n message table
 * @param {object} [res]
 * @param {string} [prevKey]
 *
 * @returns {object} a new object containing all flattened keys with respective values.
 *
 * @private
 */
function flattenObject(messages, res = {}, prevKey = null) {
    Object.keys(messages).forEach(key => {
        const k = prevKey ? `${prevKey}.${key}` : key;

        if (typeof messages[key] === 'object') {
            return flattenObject(messages[key], res, k);
        }

        if (typeof messages[key] === 'string') {
            res[k] = messages[key];
        }
    });

    return res;
}

/**
 * We use this method in order to have a vue's compatible i18n table.
 * It transforms keys such as.
 *
 * "account": {
 *      "title": {
 *          "en": "Log in"
 *      }
 *  }
 *
 * into something like this:
 * "en": {
 *     "account" {
 *         "title": "Log in"
 *     }
 * }
 *
 * which is the `vue-i18n` standard form.
 *
 * NOTE: if server responded with a key we already have declared on the
 * i18n.json file, server key takes priority over previous key.
 *
 * @param {object} localeMessages the original messages map
 * @param {object} messages the i18n message table
 *
 * @returns {object} the remapped and merged locales object
 */
export function remapMessages(localeMessages, messages) {
    const flatMessages = flattenObject(messages);
    const mergedMessages = cloneDeep(localeMessages);

    Object.keys(flatMessages).forEach(key => {
        const keyParts = key.split('.');
        const lang = keyParts.pop();
        const loc = {};

        set(loc, keyParts.join('.'), get(messages, key));
        mergedMessages[lang] = merge(mergedMessages[lang] || {}, loc);
    });

    return mergedMessages;
}

/**
 * Returns a list of all canonical languages
 *
 * @param {{[langCode: string]: {englishName: string, nativeName: string}}} [sourceList = DATA.default]
 * @returns {{[langCode: string]: {englishName: string, nativeName: string}}} a list of all languages
 */
export function getAllLanguages(sourceList = DATA.default) {
    const langsByCode = {};
    const processedCodesByEnglishName = {};

    for (const [langCode, value] of Object.entries(sourceList)) {
        if (!value?.englishName) {
            // Should not happen, just as a precaution
            continue;
        }

        // Filter out the duplicated English names, leaving either the one without region or the first one encountered
        const alreadyProcessedCodeWithSameName = processedCodesByEnglishName[value.englishName];
        if (alreadyProcessedCodeWithSameName) {
            const hasRegion = langCode.includes('-');
            if (hasRegion) {
                continue;
            }

            delete langsByCode[alreadyProcessedCodeWithSameName];
        }

        langsByCode[langCode] = value;
        processedCodesByEnglishName[value.englishName] = langCode;
    }

    return langsByCode;
}

/**
 * Retrieves the supported locales for a given event and returns them
 * as an array of key-value pairs.
 *
 * Each object contains a value and a label representing the locale.
 *
 * @param {object} event - The event object.
 * @param {boolean} [includeEmptyState=false] - Whether to include a "None" option in the result.
 * @param {{ value: string, label: string }} [emptyState={ value: '', label: 'None' }] - The empty state to use if includeEmptyState is true.
 *
 * @returns {{ value: string, label: string }[]} An array of objects representing the supported locales.
 */
export function getEventSupportedLocalesForSelect(event, includeEmptyState = false, emptyState = { value: '', label: 'None' }) {
    const supportedLocales = event.languages || ['en'];
    const allLanguages = getAllLanguages();
    const eventLanguages = [];

    if (includeEmptyState) {
        eventLanguages.push(emptyState);
    }

    for (const [key, val] of Object.entries(allLanguages)) {
        if (supportedLocales.includes(key)) {
            eventLanguages.push({ value: key, label: val.englishName });
        }
    }

    return eventLanguages;
}

/**
 * Retrieves the supported locales for a given event and returns them
 * as an object.
 *
 * @param {Object} event - The event object.
 * @param {boolean} [includeEmptyState=false] - Whether to include an empty state option.
 * @param {{ value: string, label: string }} [emptyState={ value: '', label: 'None' }] - The empty state option.
 *
 * @returns {object} An object representing the supported locales.
 */
export function getEventSupportedLocalesForKindOptions(event, includeEmptyState = false, emptyState = { value: '', label: 'None' }) {
    const selectOptions = getEventSupportedLocalesForSelect(event, includeEmptyState, emptyState);
    const options = {};
    selectOptions.forEach(option => options[option.value] = option.label);
    return options;
}

/**
 * @param {string[]} locales
 * @param {boolean} [nativeName]
 * @returns {{value:string,label:string}[]}
 */
export function getLocaleLabels(locales, nativeName) {
    const result = [];
    for (const locale of locales) {
        const names = DATA.default[locale];
        if (names) {
            result.push({ value: locale, label: nativeName ? names.nativeName : names.englishName });
        }
    }
    return result;
}

/**
 * @param {string[]} locales
 * @returns {string[]}
 */
export function filterValidLocales(locales) {
    const valid = [];
    const invalid = [];
    for (const locale of locales) {
        if (DATA.default[locale]) {
            valid.push(locale);
        } else {
            invalid.push(locale);
        }
    }

    invalid.forEach(locale => {
        console.error(`[filterValidLocales] invalid language code "${locale}"`);
    });

    return valid;
}

/**
 * This method will safely interpolate mustache values with proper translation.
 *
 * @param {string} label the label that can contain mustache markup.
 * @param {object} context the object that contains mustache interpolation values.
 * @param {object} i18n the i18n util
 * @param {boolean} [skipEscape] whether to escape the curly brakets or not.
 *
 * @returns {string} the translated label
 */
export function translateWithContext(label, context, i18n, skipEscape = false) {
    const matches = (label || '').match(/{{([^}{]*)}}/g);

    if (!matches) {
        return label;
    }

    // Search for relevand mustache keys
    const fields = matches.map(k => k.replace('{{', '').replace('}}', ''));

    if (skipEscape) {
        label = label
            .replace(/{{/g, '{{{')
            .replace(/}}/g, '}}}')
            // {{{ are turned into {{{{ > fix this
            // this could be done above with a pretty unreadable regexp
            .replace(/{{{{/g, '{{{')
            .replace(/}}}}/g, '}}}');
    }

    // We don't want to modify the original content/model for labels, thus
    // we create a clone and operate on this.
    const ctx = clone(context);

    for (const key of fields) {
        // if the desired mustache key is an object, it means that it's a
        // key that we must translate, hence we give it to the i18n util.
        if (isObject(context[key])) {
            const message = remapMessages(i18n.messages, ctx[key]);
            const label = flattenObject(message);
            if (!i18n.te(label)) {
                i18n.$updateMessages(i18n.messages, ctx[key]);
            }

            ctx[key] = i18n.t(label);
        }
    }

    return compile(label, ctx);
}
