/** @module DOM */

// Utils
import Autolinker from 'autolinker';
import Mustache from 'mustache';

const DOCUMENT_ELEMENT = 'html';

/**
 * Adds a CSS class to the HTML tag
 *
 * @param {String} className the CSS class(es) to add to the HTML tag
 * @param {Boolean} [state] A Boolean (not just truthy/falsy) value to determine whether the class should be added or removed.
 */
export function toggleDocumentClass(className, state = null) {
    if (state === null) {
        state = document
            .querySelector(DOCUMENT_ELEMENT)
            .className.indexOf(className) !== -1;
    }

    if (state === true) {
        addDocumentClass(className);
    } else {
        removeDocumentClass(className);
    }
}

/**
 * Adds class to the document HTML element
 *
 * @param {String} className the class to add to the document
 */
export function addDocumentClass(className) {
    const html = document.querySelector(DOCUMENT_ELEMENT);

    if (html.className.indexOf(className) === -1) {
        html.className += ` ${className}`;
    }
}

/**
 * Removes classes to the document HTML element
 *
 * @param {String} className the class to add to the document
 */
export function removeDocumentClass(className) {
    const html = document.querySelector(DOCUMENT_ELEMENT);

    html.className = html.className.replace(className, '').trim();
}

/**
 * Returns the closest parent with a scrollbar or the body if there are none
 *
 * @param {HTMLElement} node the node to find the scrollable parent of
 *
 * @returns {HTMLElement} the first scrollable parent
 */
export function getScrollParent(node, firstRun = true) {
    if (node === null || document.body === node) {
        return document.querySelector('html');
    }

    if (!firstRun && window.getComputedStyle(node).display !== 'inline' && node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode, false);
    }
}

/**
 * Detects links in string and replaces them with anchors
 * For list of possible options see https://github.com/gregjacobs/Autolinker.js/
 *
 * @param {String} str
 * @param {Object} options
 * @returns {String}
 */
export function linkify(str, options) {
    return Autolinker.link(str, options);
}

/**
 * Given an iframe HTML string this method tries to extract
 * its source URL.
 *
 * @param {string} input the HTML or the url string
 *
 * @returns {string} the iframe url
 */
export function extractUrlFromIframe(input) {
    const wrapper = document.createElement('div');
    wrapper.style.display = 'none';
    wrapper.innerHTML = input;

    const iframes = wrapper.querySelectorAll('iframe, embed');
    const iframeFound = iframes[0];
    wrapper.remove();
    return iframeFound ? iframeFound.src : input;
}

/**
 * This method will calculate the complete height of the given element,
 * including top and bottom margins if any.
 *
 * @param {HTMLElement} element the element to get the height from
 *
 * @returns {number} the complete height occupation of the element
 */
export function fullHeight(element) {
    const styles = window.getComputedStyle(element);
    const mTop = Number.parseFloat(styles.marginTop) || 0;
    const mBottom = Number.parseFloat(styles.marginBottom) || 0;
    const rect = element.getBoundingClientRect();

    return rect.height + mTop + mBottom;
}

/**
 * Loads the given font face
 *
 * @param {string} name the name of the font face
 * @param {string} url the URL of the font file(s)
 * @param {object} [properties={}] extra CSS rules in form of a JS object
 *
 * @returns {Promise<undefined>} The promise of the loaded font face
 */
export async function loadFontFace(name, url, properties = {}) {
    let alreadyLoaded = false;
    document.fonts.forEach(f => {
        if (f.family === name) {
            alreadyLoaded = true;
        }
    });

    if (alreadyLoaded) {
        return;
    }

    const font = new FontFace(name, `url(${url})`, properties);
    document.fonts.add(font);

    try {
        return await font.load();
    } catch (error) {
        console.error('[utils/dom] Could not load specified font', error);
        return document.fonts.delete(font);
    }
}


/**
 * Scrolls the browser window to the last section
 */
export function scrollToId(id) {
    requestAnimationFrame(() => {
        setTimeout(() => {
            const bottom = document.querySelector(id);
            const rect = bottom.getBoundingClientRect();
            const parent = getScrollParent(bottom);
            const top = rect.top + 256;

            console.debug('[utils/dom] Scrolling to', top);
            parent.scrollTo({ top, left: rect.left, behavior: 'smooth' });
        }, 100);
    });
}

/**
 * Compiles the mustache template with the given replacements
 *
 * @param {String} template
 * @param {Object} [replacements={}]
 *
 * @returns {String} the compiled template
 */
export function compile(template, replacements = {}) {
    return Mustache.render(template, replacements);
}
