// Main Vue runtime
import Vue from 'vue';

import APIDocumentation from 'docs/pages/APIDocumentation.vue';

// Utils
import { createRouter } from 'docs/core/router';
import { createLocales } from 'docs/core/locales';

import Utils from 'docs/core/utils';
import Services from 'docs/core/services';
import Plugins from 'docs/core/plugins';

// Core plugins registration
Vue.use(Utils);
Vue.use(Services);
Vue.use(Plugins, { proto: Vue });

// Application core
window.BSTG.runtime = new Vue({
    el: '.api-doc',
    name: 'Docs',
    router: createRouter(Vue),
    i18n: createLocales(Vue),
    render: h => h(APIDocumentation),
});
