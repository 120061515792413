// Utils
import hljs from 'highlight.js/lib/highlight';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import bash from 'highlight.js/lib/languages/bash';
import ruby from 'highlight.js/lib/languages/ruby';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', json);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('ruby', ruby);

/**
 * This directive highlights the code of the element is used on
 *
 * @example
 *
 * <div v-highlight>...</div>
 *
 * @category ui.utils.directive
 * @module CodeHighlight
 */
export default {
    deep: true,

    bind: (el, binding) => {
        // if a value is directly assigned to the directive, use this
        // instead of the element content.
        if (binding.value) {
            el.textContent = binding.value;
        }

        hljs.highlightBlock(el);
    },

    componentUpdated: (el, binding) => {
        // after an update, re-fill the content and then highlight
        if (binding.value) {
            el.textContent = binding.value;
            hljs.highlightBlock(el);
        }
    }
};
