/** @module Interceptors */

// Utils
import { get } from 'lodash';
import { getHomeUrl } from './url';
import {
    ROUTE_CONFIRM_ACCOUNT,
    ROUTE_LOGIN,
    ROUTE_LOGIN_WITH_MFA,
    ROUTE_LOGIN_WITH_SSO,
    ROUTE_MFA_PHONE,
    ROUTE_RESET_PASSWORD,
    ROUTE_SEND_RESET_TOKEN,
    ROUTE_SSO_DIRECT_ACCESS,
    ROUTE_USERS,
    ROUTE_VALIDATE_ACCOUNT
} from './constants';

const AUTH_ROUTE_PATHS = [
    '/',
    ROUTE_CONFIRM_ACCOUNT,
    ROUTE_LOGIN,
    ROUTE_LOGIN_WITH_MFA,
    ROUTE_LOGIN_WITH_SSO,
    ROUTE_MFA_PHONE,
    ROUTE_RESET_PASSWORD,
    ROUTE_SEND_RESET_TOKEN,
    ROUTE_SSO_DIRECT_ACCESS,
    ROUTE_USERS,
    ROUTE_VALIDATE_ACCOUNT
];

/**
 * @typedef {object} RequestError
 * @property {import('axios').AxiosResponse} response the XHR response
 * @property {boolean} [isAxiosError] is this an axios error?
 * @property {string} [location] the URL where to redirect the browser
 * @property {number} [status] the response status (only for angular XHR calls)
 * @property {object} [data] the same as response.data
 */

/**
 * This method is used to intercept all requests that result
 * with a status code outside the range of 2xx.
 *
 * @param {RequestError} error the request error
 * @returns {Promise<void>}
 */
export function requestErrorInterceptor(error) {
    // unify error responses between axios and angular's $http
    if (error.isAxiosError) {
        error.data = get(error, 'response.data');
    }

    const status = error.status || get(error, 'response.status');

    switch (status) {
        case 400:
            return badRequestHandler(error);
        case 401:
            return unauthorizedHandler(error);
        case 303:
            return redirectHandler(error);

        default:
            return Promise.reject(error);
    }
}

/**
 * This handler is used to trigger an event on the window that will
 * then be intercepted by a proper component in the UI to display
 * the error details.
 *
 * @param {RequestError} error the request error
 * @returns {Promise<void>}
 */
async function badRequestHandler(error) {
    const data = get(error, 'response.data');

    if (data) {
        const event = new CustomEvent('bad-request', { detail: data });
        window.dispatchEvent(event);
    }
    return Promise.reject(error);
}

/**
 * This method is used to intercept all requests that result
 * with a 401 (unauthorized) status code.
 *
 * It attempts to check the session again and if session is
 * valid (and access to event), then it's a specific permission
 * issue.
 *
 * If the session check will return again a 401 then the user
 * have been un-authenticated. It will pass through this
 * code again, but this time it will have `config.isSessionCall`
 * set to true and user will be redirected to the login page.
 *
 * @param {RequestError} error the request error
 * @returns {Promise<void>}
 */
async function unauthorizedHandler(error) {
    try {
        const userService = get(window, 'BSTG.services.user');

        if (get(error, 'config.isSessionCall')) {

            // This check prevents the storing of an unauthorized call
            // happening on the Auth application. We want to avoid this
            // because of the new backstage user's validation/activation.
            // In fact if the activation URL is expired or invalid, we
            // receive a 401 and if we store that invalid URL the user
            // won't be able to activate anymore.
            // See https://spotme.atlassian.net/browse/SS-20856
            const url = new URL(window.location.href);

            if (AUTH_ROUTE_PATHS.every(r => r !== url.pathname)) {
                const routerService = get(window, 'BSTG.services.router');
                routerService.saveRequestedUrl();
            }

            await logout();
        } else {
            await userService.checkSession();
        }

    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return Promise.reject(error);
    }
}

/**
 * This method is used to intercept all requests that result
 * with a 303 (See Other) status code.
 *
 * @param {RequestError} error the request error
 */
async function redirectHandler(error) {
    window.location.href = getHomeUrl(error.location);
}

/**
 * Invalidates the user's session cookie and redirects to the
 * login page.
 */
async function logout() {
    try {
        await get(window, 'BSTG.services.user').logout();
    } finally {
        get(window, 'BSTG.services.router').redirectToHome(ROUTE_LOGIN);
    }
}
