import { ClientJS } from 'clientjs';

// Classes
import BaseService from './base-service';

// Constants
import {
    USER_SESSION_ENDPOINT
} from 'libs/utils/constants';

/**
 * Path for mfa validation.
 * @constant {String} USER_SESSION_MFA_ENDPOINT
 */
const USER_SESSION_MFA_ENDPOINT = `${USER_SESSION_ENDPOINT}/mfa-code`;

/**
 * Path for mfa phone update.
 * @constant {String} USER_PHONE_MFA_ENDPOINT
 */
const USER_PHONE_MFA_ENDPOINT = `${USER_SESSION_ENDPOINT}/mfa-phone`;

/**
 * Provides utils for managing user's session.
 *
 * @example
 * import SessionService from 'libs/services/sessions';
 * ...
 * const sessionService = new SessionService(user, eventId);
 *
 * @mermaid
 *  graph TD;
 *      BaseService-->SessionService;
 */
export default class SessionService extends BaseService {
    /**
     * Attempt to log the user with given username/password
     *
     * @param {String} username
     * @param {String} password
     * @param {Object} [accepted_clauses] the user's accepted usage clauses
     *
     * @return {Promise<object>} the login process result
     */
    async login(username, password, accepted_clauses) {
        // Getting the client fingerprint for remember me purpose
        const client = new ClientJS();
        const fingerprint = client.getFingerprint();

        const credentials = {
            name: username,
            password,
            accepted_clauses,
            fingerprint
        };

        const { data } = await this.post(USER_SESSION_ENDPOINT, credentials, {
            isSessionCall: true
        });

        return data;
    }

    /**
     * Validate a mfaToken code
     * @param {string} code
     * @param {string} mfaToken
     * @returns {Promise<object>} the mfa process result
     */
    async sendMFAPhone(phone, phoneToken) {
        const { data } = await this.put(USER_PHONE_MFA_ENDPOINT, { new_phone: phone, phone_request_token: phoneToken });
        return data;
    }

    /**
     * Validate a mfaToken code
     * @param {string} code
     * @param {string} mfaToken
     * @returns {Promise<object>} the mfa process result
     */
    async validateMFA(code, mfaToken, rememberMe) {
        const responseData = { code, mfa_token: mfaToken };
        if (rememberMe) {
            // Getting the client fingerprint for remember me purpose
            const client = new ClientJS();
            responseData.fingerprint = client.getFingerprint();
        }
        const { data } = await this.post(USER_SESSION_MFA_ENDPOINT, responseData, { isSessionCall: true });
        return data;
    }

    /**
     * Ask for a new mfa code
     * @param {string} mfaToken
     * @returns {Promise<object>}
     */
    async resendMFA(mfaToken) {
        const { data } = await this.put(USER_SESSION_MFA_ENDPOINT, { mfa_token: mfaToken });
        return data;
    }

    /**
     * Logs out the current user and redirects to the login page.
     *
     * Remember to redirect the user to the home login page after the logout.
     *
     * @return {Promise} the server response
     */
    logout() {
        return this.delete(USER_SESSION_ENDPOINT);
    }

    /**
     * Check if session is still valid
     *
     * @throws when we fail to get user's session
     * @return {Promise<Boolean>}
     */
    async checkSession() {
        await this.getSession();

        return true;
    }

    /**
     * Get current users session
     *
     * @return {Promise<Object>}
     */
    getSession() {
        const suffix = this.eventId ? `/${this.eventId}` : '';
        const url = `${USER_SESSION_ENDPOINT}${suffix}`;

        return this.get(url, { isSessionCall: true });
    }
}
