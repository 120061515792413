// Classes
import BaseService from './base-service';

// Utils
import { get, isEmpty, isObject } from 'lodash';
import { getBackendURL } from 'libs/utils/url';

// Constants
import { API_BASE_PATH, API_V2_BASE_PATH } from 'libs/utils/constants';


/**
 * API full hostname URL.
 * @private
 */
const API_URL = getBackendURL();

/**
 * Package's API Documentation endpoint. Interpolation: `{{pkgName}}`, `{{version}}`
 * @private
 */
const API_DOC_PACKAGE_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/packages/{{pkgName}}/{{version}}/documentation`;

/**
 * Event's API Documentation endpoint. Interpolation: `{{eventId}}`
 * @private
 */
const API_DOC_EVENT_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/workspace/{{eventId}}/documentation`;

/**
 * Generic event's API Documentation endpoint.
 * @private
 */
const API_DOC_EVENTS_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/workspace/documentation`;

/**
 * Organization's API Documentation endpoint. Interpolation: `{{orgId}}`
 * @private
 */
const API_DOC_ORG_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/orgs/{{orgId}}/documentation`;

/**
 * Generic organization's API Documentation endpoint
 * @private
 */
const API_DOC_ORGS_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/orgs/documentation`;

/**
 * Public available packages and their versions endpoint
 * @private
 */
const PUBLIC_PACKAGES_ENDPOINT = `${API_URL}${API_BASE_PATH}/packages`;

/**
 * Direct data API documentation endpoint
 * @private
 */
const API_DOC_DIRECT_DATA_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/orgs/documentation/data`;

/**
 * Direct data files API documentation endpoint
 * @private
 */
const API_DOC_DIRECT_DATA_FILES_ENDPOINT = `${API_URL}${API_V2_BASE_PATH}/orgs/documentation/data-files`;


/**
 * Provides utils for getting API documentation data.
 *
 * @example
 * import APIDocService from 'libs/services';
 * ...
 * const apiDoc = new APIDocService();
 */
export default class APIDocService extends BaseService {
    /**
     * Given an URL this method returns all API docs details.
     *
     * @param {String} url the url from which getting the documentation from
     *
     * @return {Promise} Promise object that represents the API's documentation.
     */
    async getDocs(url) {
        const { data } = await this.getCached(url);

        if (data.hasOwnProperty('pathsByTags')) {
            // From cache, has already been processed
            return data;
        }

        data['pathsByTags'] = this.organizePathsByTags(data);

        delete data.tags;
        delete data.paths;

        // Sort definitions alphabetically
        if (isObject(data.definitions)) {
            data.definitions = Object.keys(data.definitions).sort().reduce((r, k) => ({ ...r, [k]: data.definitions[k] }), {});
        }

        // Sort security definitions alphabetically
        if (isObject(data.securityDefinitions)) {
            data.securityDefinitions = Object.keys(data.securityDefinitions).sort().reduce((r, k) => ({ ...r, [k]: data.securityDefinitions[k] }), {});
        }

        return data;
    }

    /**
     * Given a package name and a version this method returns all API docs details URL.
     *
     * @param {String} packageName the name of the package
     * @param {String} version the version of the package
     *
     * @return {String} the event's documentation URL
     */
    getPackageDocsUrl(packageName, version) {
        return API_DOC_PACKAGE_ENDPOINT
            .replace('{{pkgName}}', packageName)
            .replace('{{version}}', version);
    }

    /**
     * Given a package name and a version this method returns all API docs details.
     *
     * @param {String} packageName the name of the package
     * @param {String} version the version of the package
     *
     * @return {Promise} Promise object that represents the API's documentation.
     */
    async getPackageDocs(packageName, version) {
        return this.getDocs(this.getPackageDocsUrl(packageName, version));
    }

    /**
     * Given an event id this method returns all API docs details URL.
     *
     * @param {String} [eventId] the ID of the event
     *
     * @return {String} the event's documentation URL
     */
    getEventDocsUrl(eventId) {
        return eventId ? API_DOC_EVENT_ENDPOINT.replace('{{eventId}}', eventId) : API_DOC_EVENTS_ENDPOINT;
    }

    /**
     * Given an event id this method returns all API docs details.
     *
     * @param {String} [eventId] the ID of the event
     *
     * @return {Promise} Promise object that represents the API's documentation.
     */
    async getEventDocs(eventId) {
        return this.getDocs(this.getEventDocsUrl(eventId));
    }

    /**
     * Given an organization id this method returns all API docs details URL.
     *
     * @param {String} [orgId] the ID of the organization
     *
     * @return {String} the organization's documentation URL.
     */
    getOrgDocsUrl(orgId) {
        return orgId ? API_DOC_ORG_ENDPOINT.replace('{{orgId}}', orgId) : API_DOC_ORGS_ENDPOINT;
    }

    /**
     * Given an organization id this method returns all API docs details.
     *
     * @param {String} [orgId] the ID of the organization
     *
     * @return {Promise} Promise object that represents the API's documentation.
     */
    async getOrgDocs(orgId) {
        return this.getDocs(this.getOrgDocsUrl(orgId));
    }

    /**
     * Gets the direct data API docs details URL.
     *
     * @return {String} the direct data documentation URL.
     */
    getDirectDataDocsUrl() {
        return API_DOC_DIRECT_DATA_ENDPOINT;
    }

    /**
     * Gets the direct data files API docs details URL.
     *
     * @return {String} the direct data files documentation URL.
     */
    getDirectDataFilesDocsUrl() {
        return API_DOC_DIRECT_DATA_FILES_ENDPOINT;
    }

    /**
     * Gets the direct data API docs details.
     *
     * @return {Promise} Promise object that represents the API's documentation.
     */
    async getDirectDataDocs() {
        return this.getDocs(this.getDirectDataDocsUrl());
    }


    /**
     * Gets the direct data files API docs details.
     *
     * @return {Promise} Promise object that represents the API's documentation.
     */
    async getDirectDataFilesDocs() {
        return this.getDocs(this.getDirectDataFilesDocsUrl());
    }

    /**
     * Gets the public available packages
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async getPackages() {
        const { data } = await this.getCached(PUBLIC_PACKAGES_ENDPOINT);

        return data;
    }

    /**
     * Starting from the documentation document this method rebuild and remap the
     * paths by organizing them by tags.
     *
     * @param {Object} data the api document to start building the tags from
     * @param {Object} data.paths the paths of the APIs
     * @param {Object[]} data.tags the initial set of tags
     *
     * @returns {Object} an object having as properties the tag names and as value, description, and paths for the given tag.
     */
    organizePathsByTags(data) {
        if (!data.tags) {
            data.tags = [];
        }

        if (!data.tags.find(tag => tag.name === 'default')) {
            data.tags.push({ name: 'default' });
        }

        const resultingTags = {};

        for (const tag of data.tags) {
            tag.paths = {};
            resultingTags[tag.name] = tag;
        }

        // All given paths
        for (const [path, verbs] of Object.entries(data.paths)) {

            // All methods for the given path
            for (const [verb, details] of Object.entries(verbs)) {
                if (!details.tags) {
                    details.tags = ['default'];
                }

                for (const tagName of details.tags) {
                    if (!resultingTags[tagName].paths[path]) {
                        resultingTags[tagName].paths[path] = {};
                    }

                    // There can only be an entity on a specific path for a specific verb
                    resultingTags[tagName].paths[path][verb] = details;
                }
            }
        }

        // Cleanup empty tags
        for (const tag of Object.keys(resultingTags)) {
            if (isEmpty(resultingTags[tag].paths)) {
                delete resultingTags[tag];
            }
        }

        return Object.keys(resultingTags).sort()
            .reduce((r, k) => ({ ...r, [k]: resultingTags[k] }), {});
    }

    /**
     * Starting from a schema this method builds a properties object
     *
     * @param {Object} schema the schema from which build the property object
     * @param {Object} definitions the set of models definitions
     * @param {Object} [upperSchema] the upper level schema in case of a recursion
     *
     * @returns {Object} the properties object
     */
    parseSchema(schema = {}, definitions, upperSchema) {
        if (schema.type === 'array') {
            schema = schema.items || schema;
        }

        if (!schema.type && schema.properties) {
            schema.type = 'object';
        }

        if (this.isReference(schema)) {
            schema = this.getReference(schema, definitions);
        }

        if (!['object', 'array'].includes(schema.type) || !schema.properties) {
            if (upperSchema && !upperSchema.description && schema.description) {
                upperSchema.description = schema.description;
            }

            return schema;
        }

        // This is used for example value (JSON representation)
        const properties = {};

        // This is used for the HTML table
        const model = [];
        const schemaPropsSorted = Object.entries(schema.properties)
            .sort((a, b) => a[0] > b[0] ? 1 : -1);

        for (const [name, details] of schemaPropsSorted) {
            const definition = this.getReference(details.items || details.schema, definitions);

            if (['object', 'array'].includes(details.type)) {
                definition.description = definition.description || details.description;
                definition.properties = definition.properties || details.properties || get(details, 'items.properties');
                definition.type = definition.type || details.type;
                definition.required = details.required || get(details, 'items.required');

                details.schema = definition;

                const aryType = get(details, 'items.type');
                const subObject = this.parseSchema(details, definitions, schema);

                if (aryType) {
                    properties[name] = [subObject.properties || aryType];

                } else {
                    properties[name] = subObject.properties;
                }
            } else if (details.example) {
                properties[name] = details.example;

            } else if (details.type === 'boolean') {
                properties[name] = true;

            } else if (details.type === 'number') {
                properties[name] = 0;

            } else if (details.enum && details.enum.length) {
                properties[name] = details.enum[0];

            } else {
                properties[name] = details.type;
            }

            const schemaType = get(details, 'schema.type');

            if (!details.type && schemaType) {
                details.type = schemaType;
            }

            model.push({ name, ...details });
        }

        return { properties, model };
    }

    /**
     * Parse headers definitions
     *
     * @param {Object} headers as an object with header names as keys
     *
     * @returns {Object} headers as an array of objects and headers example
     */
    parseHeaders(headers) {

        const headersExample = Object.entries(headers || {}).reduce((acc, [headerName, headerSpec]) => {
            if (headerSpec.example) {
                acc[headerName] = headerSpec.example;
            }

            return acc;
        }, {});

        return {
            headers: Object.entries(headers || {}).map(([headerName, headerSpec]) => ({ ...{ name: headerName }, ...headerSpec })),
            headersExample
        };
    }

    /**
     * Checks if the given object is a reference ($ref) or not
     *
     * @param {Object} object the object to check
     *
     * @returns {Boolean} whether the given object is a reference or not
     */
    isReference(object) {
        return isObject(object) && object.hasOwnProperty('$ref');
    }

    /**
     * Gets the reference definition for the given object or empty object
     *
     * @param {Object} object the schema to check for reference
     * @param {Object} definitions the set of models definitions
     *
     * @return {Object} the referenced schema (could be empty)
     */
    getReference(object, definitions) {
        let definition = {};

        if (this.isReference(object)) {
            const ref = object.$ref
                .replace('#/definitions/', '')
                .replace(new RegExp(/\//, 'g'), '.');

            definition = get(definitions, ref);
        }

        return definition || {};
    }

}
