// Vanilla services
import APIDocService from 'libs/services/api-doc';
import OidcService from 'libs/services/openid-connect';
import SessionService from 'libs/services/session';

export const services = {
    apiDoc: new APIDocService(),
    oidc: new OidcService(),
    session: new SessionService()
};

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        app.prototype.$services = services;
    }
};
