import BaseService from './base-service';

// Constants
import {
    OIDC_GRANT_ENDPOINT,
    OIDC_TOKEN_ENPOINT,
    OIDC_TOKENS_ENPOINT,
    OIDC_REVOKE_TOKEN_ENPOINT
} from 'libs/utils/constants';

/**
 * Provides utils for OpenConnect management.
 *
 * @example
 * import OpenIdConnectService from 'libs/services/openid-connect';
 * ...
 * const OpenConnect = new OpenIdConnectService();
 */
export default class OpenIdConnectService extends BaseService {

    /**
     * Given a grant this method returns the consent details.
     *
     * @param {String} grant the grant to get the consent for
     *
     * @returns {Promise<Object>} the server response
     */
    async getConsentDisplayDetails(grant) {
        const url = OIDC_GRANT_ENDPOINT.replace('{{grant}}', grant);
        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }

    /**
     * Sets a set of consents to the given grant.
     *
     * @param {String} grant the grant to associate the consents to.
     * @param {Object} consent the consent to give to the grant.
     * @param {Object} consent.scope the consent scope object
     * @param {String} consent.user the user email
     *
     * @returns {Promise<Object>} the server response
     */
    async submitConsent(grant, consent) {
        const url = OIDC_GRANT_ENDPOINT.replace('{{grant}}', grant);
        const { data } = await this.post(url, consent, { withCredentials: true });

        return data;
    }

    /**
     * Gets a generic token
     *
     * @param {String} label the label of the token
     *
     * @returns {Promise<Object>} the server response
     */
    async getToken(label) {
        const { data } = await this.get(OIDC_TOKEN_ENPOINT, { params: { label }, withCredentials: true });

        return data;
    }

    /**
     * Gets the current user tokens list
     *
     * @returns {Promise<Object>} the server response
     */
    async getTokens() {
        const { data } = await this.get(OIDC_TOKENS_ENPOINT, { withCredentials: true });

        return data;
    }

    /**
     * Revokes the specified user's token
     *
     * @param {String} token the token to revoke
     *
     * @returns {Promise<Object>} the server response
     */
    async revokeToken(token) {
        const { data } = await this.post(OIDC_REVOKE_TOKEN_ENPOINT, { token }, { withCredentials: true });

        return data;
    }
}
