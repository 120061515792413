
import * as assets from 'libs/utils/assets';
import * as constants from 'libs/utils/constants';
import * as locales from 'libs/utils/locales';
import * as strings from 'libs/utils/string';
import * as url from 'libs/utils/url';

export const utils = {
    assets, locales, strings, url
};

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        app.prototype.$const = constants;
        app.prototype.$utils = utils;
    }
};
